import axios from 'axios'
import { signOut } from 'next-auth/react'

const create = () => {
    const axiosInstance = axios.create({
        baseURL: '/api',
        responseType: 'json',
        withCredentials: true,
    })

    const backendRequest = axios.create({
        baseURL: '/api/b/',
        responseType: 'json',
        withCredentials: true,
    })

    backendRequest.interceptors.response.use(undefined, (error) => {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            signOut()
        }

        return Promise.reject(error)
    })

    return {
        request: axiosInstance,
        get: (endpoint: string) => backendRequest.get(endpoint),
        delete: (endpoint: string) => backendRequest.delete(endpoint),
        post: (endpoint: string, data?: Record<string, any>) =>
            backendRequest.post(endpoint, data),
    }
}

export default create()
