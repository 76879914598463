import * as React from 'react'
import { ArrowLeftIcon } from '@heroicons/react/outline'
import { Header } from './text'
import Link from 'next/link'

interface Props {
    href: string
    label: string
}
export const GoBackButton: React.FC<Props> = ({ href, label }) => {
    return (
        <Link href={href} passHref>
            <a className="flex-row items-center mb-4 rounded text-sm hidden sm:flex">
                <ArrowLeftIcon
                    className="h-4 sm:h-5 text-black"
                    aria-hidden="true"
                />

                <Header className="ml-2" level="5">
                    {label}
                </Header>
            </a>
        </Link>
    )
}
