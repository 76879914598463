import TagManager from 'react-gtm-module'
import * as amplitude from '@amplitude/analytics-browser'

export function logEvent(name: string, props?: Record<string, any>) {
    amplitude.logEvent(name, props || {})

    TagManager.dataLayer({
        dataLayer: {
            event: name,
            ...props,
        },
    })
}
