import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { Header } from '../text'
import { classNames } from '../helpers'
import { twMerge } from 'tailwind-merge'

export interface ModalProps {
    show: boolean
    title: string
    onClose: () => void
    className?: string
}

export const Modal: React.FC<ModalProps> = ({
    show,
    onClose,
    title,
    className,
    children,
}) => {
    return (
        <Transition.Root show={show} as={React.Fragment}>
            <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                onClose={onClose}
            >
                <div className="flex items-center sm:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className={twMerge(
                                classNames(
                                    'relative inline-block align-bottom sm:align-middle bg-white rounded-lg overflow-hidden shadow-xl transform transition-all min-w-full sm:min-w-[500px]',
                                    className
                                )
                            )}
                        >
                            <div className="flex flex-row justify-between p-4 cursor-pointer">
                                <Header level="4">{title}</Header>
                                <XIcon className="h-7 w-7" onClick={onClose} />
                            </div>
                            {children}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
