import * as React from 'react'
import { classNames } from './helpers'
import { twMerge } from 'tailwind-merge'

export interface InfoBlockProps {
    color?: 'yellow' | 'blue' | 'slate' | 'green' | 'white'
    className?: string
}

export const InfoBlock: React.FC<InfoBlockProps> = ({
    color,
    className,
    children,
}) => {
    return (
        <div
            className={twMerge(
                classNames(
                    'text-sm border px-2 py-0.5 rounded-md',
                    color === 'yellow'
                        ? 'bg-amber-100 border-amber-200 text-amber-900'
                        : '',
                    color === 'blue'
                        ? 'bg-blue-50 border-blue-100 text-blue-900'
                        : '',
                    color === 'slate'
                        ? 'bg-slate-100 border-slate-200 text-slate-900'
                        : '',
                    color === 'green'
                        ? 'bg-green-100 border-green-200 text-green-900'
                        : '',
                    color === 'white'
                        ? 'bg-white border-gray-300 text-slate-950'
                        : ''
                ),
                className
            )}
        >
            {children}
        </div>
    )
}
