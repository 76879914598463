import * as React from 'react'
import { classNames } from './helpers'

export function PageHeader({
    Icon,
    title,
    subtitle,
}: {
    Icon: React.FC<React.ComponentProps<'svg'>>
    title: string
    subtitle?: string
}) {
    return (
        <div className="md:flex md:items-center md:justify-between mt-2 sm:mt-5 text-slate-800">
            <div
                className={classNames(
                    'flex-1 min-w-0 flex',
                    subtitle ? 'items-start' : 'items-center'
                )}
            >
                <Icon className="w-10 h-10 sm:w-12 sm:h-12 mr-3 -ml-1" />
                <div className="inline-block">
                    <h1 className="text-2xl font-bold sm:text-3xl">{title}</h1>
                    {subtitle && (
                        <span className="text-gray-700 font-medium mt-2">
                            {subtitle}
                        </span>
                    )}
                </div>
            </div>
        </div>
    )
}
