import * as React from 'react'
import { ChevronDoubleDownIcon } from '@heroicons/react/solid'
import { classNames, TextWithBreaks } from 'lib/shared-ui'

export const Expandable: React.FC = ({ children }) => {
    const [expanded, setExpanded] = React.useState(false)

    return (
        <div
            className={classNames(
                expanded ? '' : 'max-h-[130px]',
                'overflow-hidden relative'
            )}
        >
            {children}

            {!expanded && (
                <div
                    onClick={() => setExpanded(true)}
                    className="absolute flex justify-center bottom-0 left-0 right-0 cursor-pointer pt-8 pb-2 bg-gradient-to-t from-white via-stone-50 items-end"
                >
                    <div className="flex font-semibold items-center text-xs text-slate-800 hover:text-indigo-500 uppercase">
                        <ChevronDoubleDownIcon className="h-3 w-3 mr-2" /> SHOW
                        ALL
                    </div>
                </div>
            )}
        </div>
    )
}

export const ExpandableTextWithBreaks: React.FC<{
    text: string
    maxLength: number
}> = ({ text, maxLength }) => {
    const isShort = text.length < maxLength || text.length < maxLength + 100
    const [expanded, setExpanded] = React.useState(isShort)

    if (expanded) {
        return <TextWithBreaks text={text} />
    }

    const truncateAt = text.slice(0, maxLength).lastIndexOf('. ')
    const shortText =
        truncateAt === -1
            ? text.slice(0, maxLength)
            : text.slice(0, truncateAt + 1)

    return (
        <div>
            {shortText}
            {' ... '}
            <span
                className="text-indigo-600 cursor-pointer"
                onClick={() => setExpanded(true)}
            >
                More
            </span>
        </div>
    )
}
