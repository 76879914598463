import * as React from 'react'
import dayjs from 'dayjs'
import utcPlugin from 'dayjs/plugin/utc'
import relativeTimePlugin from 'dayjs/plugin/relativeTime'
import timezonePlugin from 'dayjs/plugin/timezone'

dayjs.extend(utcPlugin)
dayjs.extend(timezonePlugin)
dayjs.extend(relativeTimePlugin)

interface Props {
    date: string
    format: string
    fromLocal?: boolean
    className?: string
    tz?: string
}

export function utcFormat(input: string | Date, format: string) {
    return dayjs.utc(input).local().format(format)
}

export const DateFormat: React.FC<Props> = ({
    date,
    format,
    className,
    fromLocal,
    tz,
}) => {
    const formattedDate = React.useMemo(() => {
        if (tz) return dayjs(date).tz(tz).format(format)
        return fromLocal
            ? dayjs(date).format(format)
            : dayjs.utc(date).local().format(format)
    }, [date, format, fromLocal])

    return <span className={className}>{formattedDate}</span>
}

export const TimeAgo: React.FC<{
    className?: string
    date: dayjs.ConfigType
    withoutSuffix?: boolean
}> = ({ className, date, withoutSuffix }) => (
    <span className={className}>{dayjs.utc(date).fromNow(withoutSuffix)}</span>
)
