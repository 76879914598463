import * as React from 'react'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'

interface ButtonCustomProps {
    variant?: 'secondary' | 'primary' | 'green' | 'red' | 'gray' | 'link'
    type?: 'submit' | 'button'
    href?: string
    className?: string
    children: React.ReactNode
}

export type ButtonProps = (
    | React.HTMLProps<HTMLButtonElement>
    | React.HTMLProps<HTMLAnchorElement>
) &
    ButtonCustomProps

export const Button: React.FC<ButtonProps> = ({
    variant,
    className,
    children,
    href,
    target,
    ...rest
}) => {
    const classes = React.useMemo(() => {
        const list: string[] = [
            'inline-flex items-center justify-center border border-transparent transition duration-150 ease-in-out',
            'font-medium rounded-md',
            'focus:outline-none text-sm sm:text-base',
            variant === 'link'
                ? 'py-1'
                : 'shadow-sm py-1.5 px-3 focus:ring-2 focus:ring-offset-2',
            rest.disabled
                ? 'bg-opacity-50 hover:bg-opacity-75 cursor-not-allowed'
                : '',
        ]

        if (variant === 'primary') {
            list.push(
                'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-white'
            )
        }

        if (variant === 'secondary') {
            list.push(
                'bg-blue-100 hover:bg-blue-200 focus:ring-blue-500 text-blue-700'
            )
        }

        if (variant === 'green') {
            list.push(
                'bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 text-white'
            )
        }

        if (variant === 'red') {
            list.push(
                'bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 text-white'
            )
        }

        if (variant === 'gray') {
            list.push(
                'bg-slate-200 hover:bg-slate-300 focus:outline-none focus:ring-offset-2 focus:ring-slate-200 text-black'
            )
        }

        if (variant === 'link') {
            list.push(
                'focus:outline-none focus:ring-offset-2 focus:ring-slate-200 text-black'
            )
        }

        if (className) {
            list.push(className)
        }

        return list.filter(Boolean).join(' ')
    }, [variant, className, rest.disabled])

    return href ? (
        <Link href={href} passHref>
            <a
                className={twMerge(classes)}
                target={target}
                {...(rest as React.HTMLProps<HTMLAnchorElement>)}
            >
                {children}
            </a>
        </Link>
    ) : (
        <button
            className={twMerge(classes)}
            {...(rest as React.HTMLProps<HTMLButtonElement> & ButtonProps)}
        >
            {children}
        </button>
    )
}
