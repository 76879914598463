import * as React from 'react'
import { useUser } from '../../auth'
import api from '../../api'
// import { CookieConsent } from '../../cookie-consent'
import { useLocalStorage } from '../../use-localstorage'

interface AppContextProps {
    platform: 'web' | 'mobile'
    os: 'android' | 'ios' | null
    expoPushToken: string | null
    messageRN: (message: string) => void
}

const defaultContext: AppContextProps = {
    platform: 'web',
    os: null,
    expoPushToken: null,
    messageRN: sendMessageToReactNative,
}

const AppContext = React.createContext(defaultContext)

export function useApp() {
    return React.useContext(AppContext)
}

export const AppProvider: React.FC = ({ children }) => {
    const [platform, setPlatform] = useLocalStorage(
        'app-provider-platform',
        defaultContext.platform
    )

    const [platformOs, setPlatformOs] = useLocalStorage(
        'app-provider-platform-os',
        defaultContext.os
    )

    const [expoPushToken, setExpoPushToken] = useLocalStorage(
        'app-provider-expo-push-token',
        defaultContext.expoPushToken
    )

    const { id: userId } = useUser()

    React.useEffect(() => {
        async function handleEvent(event: any) {
            setPlatform('mobile')

            if (event.data.os && ['android', 'ios'].includes(event.data.os)) {
                setPlatformOs(event.data.os)
            }

            if (!userId || !event.data.expoPushToken) {
                return
            }

            setExpoPushToken(event.data.expoPushToken)

            await api.post('auth/notifications-token', {
                expoPushToken: event.data.expoPushToken,
                os: event.data.os,
            })
        }

        document.addEventListener('mobileAppMessage', handleEvent)
        return () =>
            document.removeEventListener('mobileAppMessage', handleEvent)
    }, [userId])

    return (
        <AppContext.Provider
            value={{
                platform,
                messageRN: sendMessageToReactNative,
                os: platformOs,
                expoPushToken,
            }}
        >
            {children}

            {/*{platform === 'web' && <CookieConsent />}*/}
        </AppContext.Provider>
    )
}

function sendMessageToReactNative(message: string): void {
    ;(window as any).ReactNativeWebView.postMessage(message)
}
