import * as React from 'react'
import Link from 'next/link'
import { classNames } from './helpers'
import { twMerge } from 'tailwind-merge'

export interface CalloutProps {
    type?: 'warning' | 'lofi' | 'green' | 'danger'
    size?: 'sm' | 'md'
    className?: string
    Icon?: Relocify.Icon
    cta?: {
        label: string
        href?: string
        onClick?: () => void
    }
}

export const Callout: React.FC<CalloutProps> = ({
    type,
    cta,
    size,
    Icon,
    className,
    children,
}) => {
    const isWarning = type === 'warning'
    const isLoFi = type === 'lofi'
    const isDanger = type === 'danger'
    const isGreen = type === 'green'
    const isSmall = size === 'sm'

    const ctaClassNames = classNames(
        isWarning ? 'text-yellow-600' : '',
        isLoFi || isDanger ? 'text-slate-600' : '',
        isGreen ? 'text-green-700' : '',
        !isLoFi && !isWarning ? 'text-indigo-600' : '',
        'whitespace-nowrap font-semibold uppercase text-xs tracking-wider mr-1 ml-2 sm:mr-2 sm:ml-3 cursor-pointer'
    )

    return (
        <div
            className={twMerge(
                classNames(
                    'shadow rounded-md border border-gray-300 border-l-0 mt-4 relative bg-white',
                    size === 'sm' ? 'text-sm' : 'text-md'
                ),
                className
            )}
        >
            <div
                className={classNames(
                    'flex border-l-4 rounded-md',
                    isSmall ? 'p-2' : 'p-4',
                    isWarning ? 'border-yellow-500' : '',
                    isLoFi ? 'border-slate-700' : '',
                    isGreen ? 'border-green-700' : '',
                    isDanger ? 'border-red-700' : '',
                    !isWarning && !isLoFi ? 'border-indigo-600' : ''
                )}
            >
                <div
                    className={classNames(
                        isSmall ? 'ml-2' : 'ml-3',
                        'flex flex-row justify-between items-center w-full'
                    )}
                >
                    <div className="flex flex-row items-start">
                        {Icon && (
                            <Icon
                                className={classNames(
                                    'mr-3 hidden sm:flex',
                                    isWarning ? 'text-black' : 'text-slate-700',
                                    isSmall ? 'h-6 w-6' : 'h-9 w-9'
                                )}
                            />
                        )}
                        <span className="w-full">{children}</span>
                    </div>
                    {cta && cta.href ? (
                        <Link href={cta.href} passHref>
                            <a
                                target={
                                    cta.href?.startsWith('http')
                                        ? '_blank'
                                        : undefined
                                }
                                onClick={cta.onClick}
                                rel="noopener noreferrer"
                                className={ctaClassNames}
                            >
                                {cta.label}
                            </a>
                        </Link>
                    ) : cta && cta.onClick ? (
                        <a onClick={cta.onClick} className={ctaClassNames}>
                            {cta.label}
                        </a>
                    ) : null}
                </div>
            </div>
        </div>
    )
}
