import * as React from 'react'
import TagManager from 'react-gtm-module'
import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { useRouter } from 'next/router'
import { useUser } from 'lib/auth'
import config from 'config'
import { useApp } from '../shared-ui'

// Create and Install Session Replay Plugin
const sessionReplayTracking = sessionReplayPlugin({
    debugMode: true,
})
amplitude.add(sessionReplayTracking)

const initAmplitude = (apiKey: string, userId?: string) => {
    amplitude.init(apiKey, userId, {
        autocapture: true,
        // serverZone: 'EU',
    })
}

// https://www.learnbestcoding.com/post/9/easiest-way-to-integrate-google-analytics-with-react-js-and-next-js
export const AnalyticsTag: React.FC = () => {
    const router = useRouter()
    const { isAuth, isLoading, ...user } = useUser()
    const { platform } = useApp()

    React.useEffect(() => {
        if (router.isReady && !isLoading && config.analyticsEnabled) {
            if (platform === 'web') {
                initAmplitude(config.amplitude.apiKey, user.email)
            }
            TagManager.initialize({
                gtmId: config.gtmId,
                dataLayer: {
                    event: 'app.initialize',
                    authorized: isAuth,
                    userId: user.id || null,
                    userName: user.name || null,
                    userRole: user.role || null,
                    userEmail: user.email || null,
                    platform,
                },
            })
        }
    }, [router.isReady, isLoading])

    return <></>
}
