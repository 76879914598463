import * as React from 'react'
import Linkify from 'react-linkify'

export function TextWithBreaks({ text }: { text: string }) {
    // Function to parse text and wrap bold and italic parts in <strong> and <em> tags respectively
    const parseTextForStyling = (str: string) => {
        const boldRegex = /\*\*(.*?)\*\*/g
        // Updated regex to match italic text marked by underscores
        const italicRegex = /_(.*?)_/g

        // First split and parse for bold
        const boldParts = str.split(boldRegex).map((part, index) => {
            if (index % 2 === 1) {
                // Part is bold text
                return <strong key={`b-${index}`}>{part}</strong>
            } else {
                // Part might contain italic text or be normal text
                // Split and parse for italic
                return part.split(italicRegex).map((subPart, subIndex) => {
                    if (subIndex % 2 === 1) {
                        // Sub-part is italic text
                        return <em key={`i-${subIndex}`}>{subPart}</em>
                    } else {
                        // Sub-part is normal text
                        return subPart
                    }
                })
            }
        })

        // Flatten the resulting array since italic parsing might introduce nested arrays
        return boldParts.flat()
    }

    return (
        <>
            {text.split('\n').map((line, index) => (
                <span key={index}>
                    <Linkify
                        componentDecorator={(href, text, key) => (
                            <a
                                href={href}
                                className="text-indigo-600 break-all"
                                target="_blank"
                                rel="noreferrer"
                                key={key}
                            >
                                {text}
                            </a>
                        )}
                    >
                        {parseTextForStyling(line)}
                    </Linkify>
                    <br />
                </span>
            ))}
        </>
    )
}
