import * as React from 'react'
import { classNames } from './helpers'

interface Tab {
    id: string
    name: string
}

interface Props {
    defaultTab?: string
    tabs: Tab[]
    onChange: (id: string) => void
}

export const Tabs: React.FC<Props> = ({ onChange, tabs, defaultTab }) => {
    const [activeTab, setActiveTab] = React.useState(
        () => tabs.find((tab) => tab.id === defaultTab) || tabs[0]
    )

    const handleChange = (tab: Tab) => {
        onChange(tab.id)
        setActiveTab(tab)
    }

    return (
        <nav
            className="flex space-x-4 mb-4 max-w-screen whitespace-nowrap"
            aria-label="Tabs"
        >
            {tabs.map((tab) => (
                <a
                    key={tab.name}
                    onClick={() => handleChange(tab)}
                    className={classNames(
                        activeTab.id === tab.id
                            ? 'bg-slate-200 text-slate-700'
                            : 'text-gray-500 hover:text-gray-700 cursor-pointer',
                        'px-3 py-2 font-semibold text-sm rounded-md'
                    )}
                    aria-current={activeTab.id === tab.id ? 'page' : undefined}
                >
                    {tab.name}
                </a>
            ))}
        </nav>
    )
}
