import { useRouter } from 'next/router'
import * as React from 'react'
import { logEvent } from './log-event'
import { useUser } from '../auth'

export function useLogOnRender(name: string, props?: Record<string, any>) {
    const router = useRouter()
    const { isLoading } = useUser()
    const log = () => {
        setTimeout(() => {
            logEvent(name, props || {})
        }, 500)
    }

    React.useEffect(() => {
        if (router.isReady && !isLoading) {
            log()
        }
    }, [router.isReady, name, isLoading])
}
