import * as React from 'react'
import { useRouter } from 'next/router'

const OfferContext = React.createContext<{
    can: Record<Rules, boolean>
    offer: Relocify.Offer | null
    home: Relocify.Home | null
    duplicates: Relocify.Home[]
    agency: Relocify.Agency | null
    isBusyArea: boolean
    refetch: () => void
}>({
    can: {} as Record<Rules, boolean>,
    offer: null,
    home: null,
    agency: null,
    isBusyArea: false,
    duplicates: [],
    refetch: () => {},
})

type Rules = 'comment' | 'requestViewing'

export function useOffer() {
    const {
        offer,
        home,
        duplicates,
        agency,
        refetch,
        isBusyArea,
        can: canRules,
    } = React.useContext(OfferContext)
    const router = useRouter()
    const { payment_redirect } = router.query

    if (!offer || !home) throw Error('useOffer is not wrapped in the Provider!')

    return {
        offer,
        home,
        duplicates,
        agency,
        isBusyArea,
        processingPayment: !!payment_redirect && offer.status === 'approved',
        refetch,
        can: (rule: Rules) => canRules[rule],
    }
}

export const OfferProvider: React.FC<{
    can: Record<Rules, boolean>
    offer: Relocify.Offer
    home: Relocify.Home
    duplicates: Relocify.Home[]
    agency: Relocify.Agency | null
    isBusyArea: boolean
    refetch: () => void
}> = ({
    can,
    offer,
    home,
    duplicates,
    refetch,
    agency,
    isBusyArea,
    children,
}) => {
    return (
        <OfferContext.Provider
            value={{
                can,
                offer,
                home,
                duplicates,
                agency,
                isBusyArea,
                refetch,
            }}
        >
            {children}
        </OfferContext.Provider>
    )
}
