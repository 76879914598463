import * as React from 'react'
import { useLocalStorage } from '../use-localstorage'

interface AppContextProps {
    completed: string[]
    markAsCompleted: (step: string) => void
}

const defaultContext: AppContextProps = {
    completed: [],
    markAsCompleted: () => {},
}

const AppTourContext = React.createContext<AppContextProps>(defaultContext)

function toShow(id: string, dependencies: string[], completed: string[]) {
    if (dependencies) {
        const pendingDeps = dependencies.filter((d) => !completed.includes(d))

        if (pendingDeps.length > 0) return false
    }

    return !completed.includes(id)
}

export function useAppTourStep(id: string, dependencies: string[]) {
    const tourContext = React.useContext(AppTourContext)
    if (!tourContext) throw Error('useAppTour is not wrapped in the Provider!')
    const visible = toShow(id, dependencies, tourContext.completed)
    return { visible, markAsCompleted: tourContext.markAsCompleted }
}

export const AppTourProvider: React.FC = ({ children }) => {
    const [completed, setCompleted] = useLocalStorage(
        'app-tour-completed-steps',
        []
    )

    return (
        <AppTourContext.Provider
            value={{
                completed,
                markAsCompleted: (step) => {
                    setCompleted([...completed, step])
                },
            }}
        >
            {children}
        </AppTourContext.Provider>
    )
}
