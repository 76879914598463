import * as React from 'react'

export function useLoadMore<T>(
    data: T[],
    batchSize: number,
    append: boolean = true
): [T[], () => void, boolean] {
    const [displayedCount, setDisplayedCount] = React.useState(batchSize)

    React.useEffect(() => {
        if (data.length <= batchSize) {
            setDisplayedCount(data.length)
        }
    }, [data, batchSize])

    const items = append
        ? data.slice(0, displayedCount)
        : data.slice(Math.max(data.length - displayedCount, 0))

    const loadMore = () => {
        setDisplayedCount((prevCount) =>
            Math.min(prevCount + batchSize, data.length)
        )
    }

    const hasMore = append
        ? data.length > displayedCount
        : displayedCount < data.length

    return [items, loadMore, hasMore]
}
