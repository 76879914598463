import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { classNames, Header } from 'lib/shared-ui/index'
import { ArrowLeftIcon, XIcon } from '@heroicons/react/outline'

interface ModalProps {
    className?: string
    show: boolean
    fromLeft?: boolean
    onClose: () => void
    initialFocus?: React.MutableRefObject<HTMLElement | null>
}

export const Drawer: React.FC<ModalProps> = ({
    className,
    children,
    show,
    fromLeft,
    onClose,
    initialFocus,
}) => {
    return (
        <Transition.Root show={show} as={React.Fragment}>
            <Dialog
                as="div"
                className={classNames(
                    'fixed inset-0 z-10 overflow-hidden',
                    className
                )}
                onClose={onClose}
                initialFocus={initialFocus}
            >
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div
                        className={classNames(
                            'pointer-events-none fixed inset-y-0 flex max-w-full',
                            fromLeft ? 'left-0' : 'right-0 pl-0'
                        )}
                    >
                        <Transition.Child
                            as={React.Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom={
                                fromLeft
                                    ? '-translate-x-full'
                                    : 'translate-x-full'
                            }
                            enterTo={
                                fromLeft ? 'translate-x-0' : 'translate-x-0'
                            }
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom={
                                fromLeft ? 'translate-x-0' : 'translate-x-0'
                            }
                            leaveTo={
                                fromLeft
                                    ? '-translate-x-full'
                                    : 'translate-x-full'
                            }
                        >
                            {children}
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export const DrawerCloseButton: React.FC<
    Pick<ModalProps, 'fromLeft' | 'onClose'>
> = ({ fromLeft, onClose }) => {
    return (
        <Transition.Child
            as={React.Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div>
                <div className="hidden sm:flex absolute top-0 left-0 ml-4 pt-4 sm:-ml-10 sm:pr-4">
                    <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={onClose}
                    >
                        <span className="sr-only">Close panel</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div
                    onClick={onClose}
                    className="flex sm:hidden flex-row items-center mb-4 absolute z-20 bg-white sm:bg-transparent px-2 py-1 rounded bg-opacity-70 ml-3 mt-3 text-xs"
                >
                    <ArrowLeftIcon
                        className="h-4 sm:h-5 text-black"
                        aria-hidden="true"
                    />

                    <Header className="ml-2" level="5">
                        Go back
                    </Header>
                </div>
            </div>
        </Transition.Child>
    )
}
