import * as React from 'react'
import { SVGProps } from 'react'
import { classNames } from '../helpers'

interface IconProps {
    size: number
    className?: string
}

const Icon: React.FC<IconProps & SVGProps<SVGSVGElement>> = ({
    size,
    className,
    ...svgProps
}) => (
    <svg
        className={className}
        height={size}
        width={size}
        viewBox={`0 0 40 40`}
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        {...svgProps}
    >
        <path
            d="M5.63 10.161c5.616.773 10.446 3.91 13.908 7.235a.66.66 0 0 0 .914 0c3.462-3.325 8.292-6.462 13.908-7.235a.654.654 0 0 1 .704.412.654.654 0 0 1-.236.782C29.256 15.256 24.43 21.376 20.906 26.6c-.21.313-.535.484-.912.484-.376 0-.701-.172-.912-.484-3.524-5.225-8.35-11.344-13.921-15.245-.253-.177-.349-.495-.236-.782s.4-.454.705-.412z"
            style={{
                fillRule: 'evenodd',
                clipRule: 'evenodd',
            }}
        />
    </svg>
)

interface LogoProps {
    size?: 'md' | 'lg'
    color?: 'brand' | 'dark'
}

export const Logo: React.FC<LogoProps> = ({ size = 'md', color = 'dark' }) => {
    return (
        <b>
            <span className="flex flex-row items-center mr-2">
                <Icon
                    size={size === 'md' ? 30 : 35}
                    className={classNames(
                        color === 'dark' ? 'fill-slate-900' : 'fill-indigo-800',
                        'mr-1.5 mt-0.5'
                    )}
                />
                <span
                    className={classNames(
                        size === 'md' ? 'text-[20px]' : 'text-[25px]',
                        color === 'dark' ? 'text-slate-900' : 'text-indigo-800',
                        'mr-1 font-bold'
                    )}
                >
                    Relocify
                </span>
            </span>
        </b>
    )
}
