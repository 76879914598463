import * as React from 'react'

const RelocationContext = React.createContext<Relocify.Relocation | null>(null)

export function useRelocation() {
    const relocation = React.useContext(RelocationContext)
    if (!relocation)
        throw Error('useRelocation is not wrapped in the Provider!')

    return relocation
}

export const RelocationProvider: React.FC<{
    relocation: Relocify.Relocation
}> = ({ relocation, children }) => {
    return (
        <RelocationContext.Provider value={relocation}>
            {children}
        </RelocationContext.Provider>
    )
}
