import * as React from 'react'

interface Props {
    by: number
}

export const Delay: React.FC<Props> = ({ by, children }) => {
    const show = useDelay(by)
    return show ? <>{children}</> : null
}

export function useDelay(by: number) {
    const [show, setShow] = React.useState(false)

    React.useEffect(() => {
        const timeout = setTimeout(() => setShow(true), by)
        return () => clearTimeout(timeout)
    }, [by])

    return show
}
